html, body {
  height: 100%;
}
body {
  margin: 0;
}
#root{
  height: 100%;
}
.app{
  height: 100%;
  .main {
    height: 100%;
    padding: 20px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 58px;
  }
  .navbar{
    background-color: rgb(20 3 22)!important;
    .nav-item{
      a{
        color:white!important;  
      }
    }
  }
}

.profile{
  box-sizing: inherit;
  display: flex;
  flex-flow: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  -webkit-box-pack: start;
  justify-content: flex-start;
  gap: 1rem;
  will-change: transform, opacity, height;
  border-radius: 1rem;
  background-color: white;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 24px 48px;
  border: 1px solid transparent;
  width: 35rem;
  max-width: calc(100vw - 5rem);
  margin: 0px 1.75rem;
  padding: 2.375rem 2rem 3rem;
  .mydetails{
    .label{
      color: grey;
      font-size: 14px;
    }
    .row{
      margin-bottom: 10px;
    }
  }
  .logo{
    box-sizing: inherit;
    display: flex;
    flex-flow: row;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: start;
    justify-content: flex-start;
    height: 28px;
    object-fit: cover;
    img{
      display: inline-block;
      height: 100%;
    }
  }
  .title{
    box-sizing: border-box;
    color: rgb(20, 20, 20);
    font-family: inherit;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5;
    border-bottom: 1px solid #e5e0e0;
    -webkit-font-smoothing: auto !important;
  }
  .my-form{
    color: rgb(20, 20, 20);
    font-family: inherit;
    font-size: 0.8125rem;
    line-height: 1.375;
    -webkit-box-align: center;
    align-items: center;
    -webkit-font-smoothing: auto !important;
    .subtitle{
      box-sizing: border-box;
      color: rgba(20, 20, 20, 0.65);
      margin: 0px;
      font-size: 1rem;
      font-family: inherit;
      font-weight: 400;
      line-height: 1.25;
      -webkit-font-smoothing: auto !important;
    }
    
  }
}

.btn-clerk{
  margin: 0px;
  padding: 0.625rem 1.25rem;
  border: 0px;
  outline: 0px;
  user-select: none;
  cursor: pointer;
  background-color: var(--accent);
  color: white;
  border-radius: 0.5em;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 100ms;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 0.6875rem;
  letter-spacing: 0.5px;
  line-height: 1;
  text-transform: uppercase;
  min-height: 2.25rem;
  width: 100%;
  --accentLightest: hsla(252, 100%, 69.8%, 0.3);
  --accentLighter: hsla(252, 100%, 63%, 0.3);
  --accent: hsla(252, 100%, 63%, 1);
  --accentDark: hsla(252, 100%, 50.25%, 1);
  --accentDarker: hsla(252, 100%, 37.5%, 1);
  -webkit-font-smoothing: auto !important;
  &:hover{
    background-color: var(--accentDark);
    color: white;
  }
}

.btn-clerk-danger{
  margin: 0px;
  padding: 0.625rem 1.25rem;
  border: 0px;
  outline: 0px;
  user-select: none;
  cursor: pointer;
  color: white;
  border-radius: 0.5em;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 100ms;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 0.6875rem;
  letter-spacing: 0.5px;
  line-height: 1;
  text-transform: uppercase;
  min-height: 2.25rem;
  width: 100%;
  -webkit-font-smoothing: auto !important;
  &:hover{
    color: white;
  }
}

.cl-organizationSwitcherPopoverActionButton__createOrganization{
  display: none;
}
.custom-loading{
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

.spinner-border{
  margin-right: 5px;
}